// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_CHECK = 'LOGIN_CHECK';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_USER = 'GET_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';

// Alerts
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// Images
export const GET_IMAGES = 'GET_IMAGES';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';
export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_SUCCESS'
export const UPLOAD_IMAGES_FAIL = 'UPLOAD_IMAGES_FAIL'
export const DELETE_IMAGES = 'DELETE_IMAGES';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

// Bookmarks
export const GET_BOOKMARKS = 'GET_BOOKMARKS';
export const SAVE_BOOKMARK = 'SAVE_BOOKMARK';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const UPDATE_BOOKMARK = 'UPDATE_BOOKMARK';
export const SORT_BOOKMARKS = 'SORT_BOOKMARKS';
export const SET_ACTIVE_BOOKMARK = 'SET_ACTIVE_BOOKMARK';

// Groups
export const TOGGLE_SHOW_ALL_GROUPS = 'TOGGLE_SHOW_ALL_GROUPS';
export const TOGGLE_SHOW_ALL_IMAGES = 'TOGGLE_SHOW_ALL_IMAGES';
export const GET_GROUPS = 'GET_GROUPS';
export const SAVE_GROUP = 'SAVE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const ADD_MEMBERS = 'ADD_MEMBERS';
export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
export const GET_ONE_GROUP = 'GET_ONE_GROUP';
export const CLEAR_GROUP = 'CLEAR_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP'

// Edit
export const START_SELECTION = 'START_SELECTION';
export const END_SELECTION = 'END_SELECTION';


