import React from 'react';

const GridEmpty = () => {

    return (
        <div className="grid__empty">
            There's no images here yet.
        </div>
    )
}

export default GridEmpty ;
